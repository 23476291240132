import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  QueryFunctionContext,
} from 'react-query';
import { axiosHelper } from './axiosHelper';
import { SettingsSystems } from './api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type GQLAddress = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type GQLAlertActive = {
  alertState?: Maybe<Scalars['Boolean']>;
  alertType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  measure_name?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  thumbprint?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
};

export type GQLAlertCount = {
  alertsCount?: Maybe<Scalars['Int']>;
};

export type GQLAlertHistory = {
  alertState?: Maybe<Scalars['Boolean']>;
  alertType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  dismissalDatetime?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  measure_name?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  thumbprint?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  userEmailAddress?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userLastName?: Maybe<Scalars['String']>;
  userSub?: Maybe<Scalars['String']>;
};

export type GQLAnalytics = {
  aggregation?: Maybe<Scalars['String']>;
  companySiteId?: Maybe<Scalars['ID']>;
  data?: Maybe<Array<Maybe<GQLAnalyticsData>>>;
};

export type GQLAnalyticsData = {
  acidicChemical?: Maybe<Scalars['Float']>;
  acidicCost?: Maybe<Scalars['Float']>;
  avgCost?: Maybe<Scalars['Float']>;
  beerBrand?: Maybe<Scalars['String']>;
  causticConsumption?: Maybe<Scalars['Float']>;
  causticCost?: Maybe<Scalars['Float']>;
  clusterTestFailurePct?: Maybe<Scalars['Float']>;
  coolingEnergy?: Maybe<Scalars['Float']>;
  coolingEnergyCost?: Maybe<Scalars['Float']>;
  dawaterConsumption?: Maybe<Scalars['Float']>;
  dawaterCost?: Maybe<Scalars['Float']>;
  degreeOfEfficiency?: Maybe<Scalars['Float']>;
  electricalCost?: Maybe<Scalars['Float']>;
  electricalEnergy?: Maybe<Scalars['Float']>;
  enzymaticConsumption?: Maybe<Scalars['Float']>;
  enzymaticCost?: Maybe<Scalars['Float']>;
  hazeCentrifugeOut?: Maybe<Scalars['Float']>;
  hazeFilterOut?: Maybe<Scalars['Float']>;
  key?: Maybe<Scalars['String']>;
  membraneCleanerCost?: Maybe<Scalars['Float']>;
  membraneCleanerKg?: Maybe<Scalars['Float']>;
  netCapacity?: Maybe<Scalars['Float']>;
  oxygen?: Maybe<Scalars['Float']>;
  peroxideConsumption?: Maybe<Scalars['Float']>;
  peroxideCost?: Maybe<Scalars['Float']>;
  processedVolume?: Maybe<Scalars['Float']>;
  systemId?: Maybe<Scalars['ID']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalStabilizedBeer?: Maybe<Scalars['Float']>;
  volumeFiltered?: Maybe<Scalars['Float']>;
  waterConsumption?: Maybe<Scalars['Float']>;
  waterCost?: Maybe<Scalars['Float']>;
};

export type GQLBeerBrand = {
  abv?: Maybe<Scalars['Int']>;
  beerType?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companySiteId?: Maybe<Scalars['String']>;
  fermented?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  plato?: Maybe<Scalars['Int']>;
  rawMaterial?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type GQLBlock = {
  areaId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companySiteId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  liveKPI?: Maybe<GQLBlockLiveKpi>;
  liveStatus?: Maybe<GQLBlockLiveStatus>;
  provisioning?: Maybe<GQLBlockProvisioning>;
  sortOrder?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
  telemetryNumber?: Maybe<Scalars['String']>;
};

export type GQLBlockLiveKpi = {
  estCapacityLm2?: Maybe<Scalars['Float']>;
  flowHlH?: Maybe<Scalars['Float']>;
  tmpBar?: Maybe<Scalars['Float']>;
};

export type GQLBlockLiveStatus = {
  calibrationStatus?: Maybe<Scalars['Boolean']>;
  degreeOfEfficiencyMebak?: Maybe<Scalars['Float']>;
  installedClusters?: Maybe<Scalars['Int']>;
  operationalClusters?: Maybe<Scalars['Int']>;
  processedVolumeHl?: Maybe<Scalars['Float']>;
  program?: Maybe<Scalars['String']>;
  recentRunningStatus?: Maybe<Array<Maybe<GQLBlockRecentRunningStatus>>>;
  sequenceDescription?: Maybe<Scalars['String']>;
  sequenceEstEndTime?: Maybe<Scalars['String']>;
  sequenceStartTime?: Maybe<Scalars['String']>;
  sequenceStatus?: Maybe<Scalars['String']>;
  timeToMaxdPSeconds?: Maybe<Scalars['Int']>;
};

export type GQLBlockProvisioning = {
  clusters?: Maybe<GQLBlockProvisioningCluster>;
};

export type GQLBlockProvisioningCluster = {
  quantity?: Maybe<Scalars['Int']>;
};

export type GQLBlockRecentRunningStatus = {
  percent?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type GQLCluster = {
  areaId?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  calibrationStatus?: Maybe<Scalars['String']>;
  columnId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companySiteId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrityStatus?: Maybe<GQLClusterIntegrityStatus>;
  liveStatus?: Maybe<GQLClusterLiveStatus>;
  sortOrder?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
  testResult?: Maybe<Scalars['String']>;
};

export type GQLClusterIntegrityStatus = {
  testResult?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type GQLClusterLiveStatus = {
  currentStatus?: Maybe<Scalars['String']>;
  testResult?: Maybe<Scalars['String']>;
  testTime?: Maybe<Scalars['String']>;
};

export type GQLClusterStatusTestTime = {
  ScalarValue?: Maybe<Scalars['String']>;
};

export type GQLColumn = {
  areaId?: Maybe<Scalars['String']>;
  cassettes?: Maybe<Scalars['Int']>;
  columnRecentRunningStatus?: Maybe<GQLColumnRecentRunningStatus>;
  companyId?: Maybe<Scalars['String']>;
  companySiteId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  provisioning?: Maybe<GQLColumnProvisioning>;
  sortOrder?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
};

export type GQLColumnActualDp = {
  dP?: Maybe<Scalars['Float']>;
  measure_name?: Maybe<Scalars['String']>;
};

export type GQLColumnProvisioning = {
  clusters?: Maybe<GQLColumnProvisioningCluster>;
};

export type GQLColumnProvisioningCluster = {
  quality?: Maybe<Scalars['Int']>;
};

export type GQLColumnRecentRunningStatus = {
  actualDp?: Maybe<GQLColumnActualDp>;
  currentColumnStatus?: Maybe<Scalars['String']>;
  recentRunningStatus?: Maybe<Array<Maybe<GQLColumnRunningStatus>>>;
};

export type GQLColumnRunningStatus = {
  percent?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type GQLCompany = {
  address?: Maybe<GQLAddress>;
  companySiteCount?: Maybe<GQLCompanySitesCount>;
  companySystemsCount?: Maybe<GQLCompanySystemsCount>;
  id: Scalars['ID'];
  liveStatus?: Maybe<GQLCompanyLiveStatus>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type GQLCompanyInfo = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GQLCompanyLiveStatus = {
  systems?: Maybe<Array<Maybe<GQLCompanyLiveStatusSystem>>>;
};

export type GQLCompanyLiveStatusSystem = {
  count?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type GQLCompanySite = {
  address?: Maybe<GQLAddress>;
  companyId?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  liveKPI?: Maybe<GQLCompanySiteLiveKpi>;
  liveStatus?: Maybe<GQLCompanySiteLiveStatus>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  siteSystemsCount?: Maybe<GQLSiteSystemsCount>;
  status?: Maybe<Scalars['String']>;
};

export type GQLCompanySiteLiveKpi = {
  efficiencyRank?: Maybe<Scalars['Int']>;
  productionCumCleanTimeMinutes?: Maybe<Scalars['Int']>;
  productionCumIdleStandbyTimeMinutes?: Maybe<Scalars['Int']>;
  productionCumProductionTimeMinutes?: Maybe<Scalars['Int']>;
  siteEfficiencyPct?: Maybe<Scalars['Float']>;
};

export type GQLCompanySiteLiveStatus = {
  activeAlertCount?: Maybe<Scalars['Int']>;
  systems?: Maybe<Array<Maybe<GQLCompanySiteLiveStatusSystem>>>;
};

export type GQLCompanySiteLiveStatusSystem = {
  count?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type GQLCompanySitesCount = {
  count?: Maybe<Scalars['Int']>;
};

export type GQLCompanySystemsCount = {
  cbs?: Maybe<Scalars['Int']>;
  cfs?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  keraflux?: Maybe<Scalars['Int']>;
  profi?: Maybe<Scalars['Int']>;
};

export type GQLConsumable = {
  areaId?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  clusterId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companySiteId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  liveKPI?: Maybe<GQLConsumableLiveKpi>;
  liveStatus?: Maybe<GQLConsumableLiveStatus>;
  productNumber?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
};

export type GQLConsumableLiveKpi = {
  totalCipCycles?: Maybe<Scalars['Int']>;
  totalFilteredL?: Maybe<Scalars['Float']>;
};

export type GQLConsumableLiveStatus = {
  blockNumber?: Maybe<Scalars['Int']>;
  clusterNumber?: Maybe<Scalars['Int']>;
  installationTime?: Maybe<Scalars['String']>;
  moduleId?: Maybe<Scalars['String']>;
  moduleNumber?: Maybe<Scalars['Int']>;
  pinCount?: Maybe<Scalars['Int']>;
  pins: Array<GQLConsumablePin>;
  placeNumber?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type GQLConsumablePin = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type GQLExecutiveSummary = {
  companySiteId?: Maybe<Scalars['ID']>;
  data?: Maybe<Array<Maybe<GQLExecutiveSummaryData>>>;
};

export type GQLExecutiveSummaryData = {
  acidicChemical?: Maybe<Scalars['Float']>;
  beerLossByFilterLoss?: Maybe<Scalars['Float']>;
  causticConsumption?: Maybe<Scalars['Float']>;
  dawaterConsumption?: Maybe<Scalars['Float']>;
  degreeOfEfficiency?: Maybe<Scalars['Float']>;
  electricalEnergy?: Maybe<Scalars['Float']>;
  kWHl?: Maybe<Scalars['Float']>;
  membraneCleaner?: Maybe<Scalars['Float']>;
  netCapacity?: Maybe<Scalars['Float']>;
  peroxideConsumption?: Maybe<Scalars['Float']>;
  productionDuration?: Maybe<Scalars['Float']>;
  stabilizationCost?: Maybe<Scalars['Float']>;
  systemId?: Maybe<Scalars['ID']>;
  time_end?: Maybe<Scalars['String']>;
  time_start?: Maybe<Scalars['String']>;
  totalCIPs?: Maybe<Scalars['Float']>;
  totalStabilizedBeer?: Maybe<Scalars['Float']>;
  volumeFiltered?: Maybe<Scalars['Float']>;
  waterConsumption?: Maybe<Scalars['Float']>;
};

export type GQLFilterRun = {
  brandName?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  filterRunId?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
  totalRecoveredBeer?: Maybe<Scalars['Float']>;
  volumeFiltered?: Maybe<Scalars['Float']>;
};

export type GQLFilterRunBatchDetails = {
  batchEndTime?: Maybe<Scalars['String']>;
  batchStartTime?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  endTMP?: Maybe<Scalars['Float']>;
  endVolume?: Maybe<Scalars['Float']>;
  startTMP?: Maybe<Scalars['Float']>;
};

export type GQLFilterRunId = {
  averageFlow?: Maybe<Scalars['Float']>;
  brandName?: Maybe<Scalars['String']>;
  degreeOfEfficiency?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['String']>;
  filterRunId?: Maybe<Scalars['String']>;
  fluxRate?: Maybe<Scalars['Float']>;
  netCapacity?: Maybe<Scalars['Float']>;
  oxygen?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
  throughput?: Maybe<Scalars['Float']>;
  totalCIPS?: Maybe<Scalars['Int']>;
  totalRunTime?: Maybe<Scalars['Float']>;
  turbidity?: Maybe<Scalars['Float']>;
  turbidity25?: Maybe<Scalars['Float']>;
  turbidity90?: Maybe<Scalars['Float']>;
  volumeFiltered?: Maybe<Scalars['Float']>;
};

export type GQLIntegrityTest = {
  subsystemId?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['ID']>;
  systemType?: Maybe<Scalars['String']>;
  testCompletionTime?: Maybe<Scalars['String']>;
  testId?: Maybe<Scalars['String']>;
  testResult?: Maybe<Scalars['String']>;
};

export type GQLModule = {
  areaId?: Maybe<Scalars['String']>;
  blockId?: Maybe<Scalars['String']>;
  clusterId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companySiteId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sortOrder?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
};

export type GQLQuery = {
  countAlerts?: Maybe<GQLAlertCount>;
  getAnalytics?: Maybe<GQLAnalytics>;
  getBeerBrand?: Maybe<GQLBeerBrand>;
  getBlock?: Maybe<GQLBlock>;
  getCluster?: Maybe<GQLCluster>;
  getColumn?: Maybe<GQLColumn>;
  getCompany?: Maybe<GQLCompany>;
  getCompanySite?: Maybe<GQLCompanySite>;
  getConsumable?: Maybe<GQLConsumable>;
  getExecutiveSummary?: Maybe<GQLExecutiveSummary>;
  getFilterRunId?: Maybe<Array<Maybe<GQLFilterRunId>>>;
  getModule?: Maybe<GQLModule>;
  getReport?: Maybe<GQLReport>;
  getReportDefinition?: Maybe<GQLReportDefinition>;
  getSystem?: Maybe<GQLSystem>;
  getSystemStats?: Maybe<GQLSystemStat>;
  getUser?: Maybe<GQLUser>;
  listActiveAlerts?: Maybe<Array<Maybe<GQLAlertActive>>>;
  listAlertsHistory?: Maybe<Array<Maybe<GQLAlertHistory>>>;
  listBeerBrands?: Maybe<Array<Maybe<GQLBeerBrand>>>;
  listBlocks?: Maybe<Array<Maybe<GQLBlock>>>;
  listClusters?: Maybe<Array<Maybe<GQLCluster>>>;
  listColumns?: Maybe<Array<Maybe<GQLColumn>>>;
  listCompanies?: Maybe<Array<Maybe<GQLCompany>>>;
  listCompanySites?: Maybe<Array<Maybe<GQLCompanySite>>>;
  listConsumables?: Maybe<Array<Maybe<GQLConsumable>>>;
  listFilterRuns?: Maybe<Array<Maybe<GQLFilterRun>>>;
  listModules?: Maybe<Array<Maybe<GQLModule>>>;
  listReportDefinitions?: Maybe<Array<Maybe<GQLReportDefinition>>>;
  listReports?: Maybe<Array<Maybe<GQLReport>>>;
  listSystems?: Maybe<Array<Maybe<GQLSystem>>>;
  listUsers?: Maybe<Array<Maybe<GQLUser>>>;
};

export type GQLQueryCountAlertsArgs = {
  alertType?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type GQLQueryGetAnalyticsArgs = {
  aggregation: Scalars['String'];
  companySiteId: Scalars['ID'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  systemIds: Scalars['String'];
};

export type GQLQueryGetBeerBrandArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetBlockArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetClusterArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetColumnArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetCompanyArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetCompanySiteArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetConsumableArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetExecutiveSummaryArgs = {
  aggregation: Scalars['String'];
  companySiteId: Scalars['ID'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GQLQueryGetFilterRunIdArgs = {
  endTime: Scalars['String'];
  filterRunId: Scalars['String'];
  id: Scalars['ID'];
  startTime: Scalars['String'];
};

export type GQLQueryGetModuleArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetReportArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetReportDefinitionArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetSystemArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetSystemStatsArgs = {
  compareEndTime: Scalars['String'];
  compareFilterRunId?: InputMaybe<Scalars['String']>;
  compareStartTime: Scalars['String'];
  endTime: Scalars['String'];
  filterRunId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  interval: Scalars['String'];
  parameter: Scalars['String'];
  startTime: Scalars['String'];
};

export type GQLQueryGetUserArgs = {
  id: Scalars['ID'];
};

export type GQLQueryListActiveAlertsArgs = {
  alertType?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  systemId?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListAlertsHistoryArgs = {
  alertType?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  systemId?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListBeerBrandsArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListBlocksArgs = {
  areaId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListClustersArgs = {
  areaId?: InputMaybe<Scalars['String']>;
  blockId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListColumnsArgs = {
  areaId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListCompanySitesArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListConsumablesArgs = {
  areaId?: InputMaybe<Scalars['String']>;
  blockId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  productNumber?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListFilterRunsArgs = {
  endTime: Scalars['String'];
  id: Scalars['ID'];
  startTime: Scalars['String'];
};

export type GQLQueryListModulesArgs = {
  areaId?: InputMaybe<Scalars['String']>;
  blockId?: InputMaybe<Scalars['String']>;
  clusterId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListReportDefinitionsArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  systemId?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListReportsArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  systemId?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListSystemsArgs = {
  areaId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
};

export type GQLQueryListUsersArgs = {
  companyId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  sub?: InputMaybe<Scalars['String']>;
};

export type GQLReport = {
  aggregation?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  errorDetails?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  generatedOn?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  reportS3Key?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusLastUpdate?: Maybe<Scalars['String']>;
  systems?: Maybe<Array<Maybe<GQLReportSiteIdSystemId>>>;
  type?: Maybe<Scalars['String']>;
};

export type GQLReportDefinition = {
  aggregation?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reportName?: Maybe<Scalars['String']>;
  reportType?: Maybe<Scalars['String']>;
  systems?: Maybe<Array<Maybe<GQLReportDefinitionSiteIdSystemId>>>;
};

export type GQLReportDefinitionSiteIdSystemId = {
  companySiteId?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
};

export type GQLReportSiteIdSystemId = {
  companySiteId?: Maybe<Scalars['String']>;
  systemId?: Maybe<Scalars['String']>;
};

export type GQLRoleInfo = {
  roleName?: Maybe<Scalars['String']>;
};

export type GQLSettingsInfo = {
  dateFormat?: Maybe<Scalars['String']>;
  favorites?: Maybe<Array<Maybe<GQLUserFavorites>>>;
  language?: Maybe<Scalars['String']>;
  monthFormat?: Maybe<Scalars['String']>;
  pressureUnits?: Maybe<Scalars['String']>;
  volumeUnits?: Maybe<Scalars['String']>;
};

export type GQLSiteSystemsCount = {
  cbs?: Maybe<Scalars['Int']>;
  cfs?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  keraflux?: Maybe<Scalars['Int']>;
  profi?: Maybe<Scalars['Int']>;
};

export type GQLSystem = {
  areaId?: Maybe<Scalars['String']>;
  blockCount?: Maybe<Scalars['Int']>;
  brandId?: Maybe<Scalars['String']>;
  cleanUpLiveKPI?: Maybe<GQLSystemCleanUpLiveKpi>;
  cleanUpLiveStatus?: Maybe<GQLSystemCleanUpLiveStatus>;
  clusterCount?: Maybe<Scalars['Int']>;
  clusterStatus?: Maybe<Array<Maybe<GQLSystemClusterStatus>>>;
  columnCount?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
  companySiteId?: Maybe<Scalars['String']>;
  deploymentDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  liveKPI?: Maybe<GQLSystemLiveKpi>;
  liveRegeneration?: Maybe<GQLSystemLiveRegeneration>;
  liveStatus?: Maybe<GQLSystemLiveStatus>;
  membraneArea?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  moduleCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  plcType?: Maybe<Scalars['String']>;
  provisioning?: Maybe<GQLSystemProvision>;
  serialNumber?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  squareMetersPerModule?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  systemSettingsInfo?: SettingsSystems;
};

export type GQLSystemCleanUpLiveKpi = {
  acidLow?: Maybe<Scalars['Boolean']>;
  causticLow?: Maybe<Scalars['Boolean']>;
  membraneCleanerLow?: Maybe<Scalars['Boolean']>;
  peroxideLow?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['String']>;
  waterConsumptionM3Hl?: Maybe<Scalars['Float']>;
};

export type GQLSystemCleanUpLiveStatus = {
  currentCleanUpUnitStatus?: Maybe<Scalars['String']>;
  nextpassivationStartTime?: Maybe<Scalars['String']>;
  passivationStartTime?: Maybe<Scalars['String']>;
  productionsBeforePassivation?: Maybe<Scalars['Float']>;
  recentCleanUpUnitStatus?: Maybe<
    Array<Maybe<GQLSystemCleanupLiveStatusUnitStatus>>
  >;
  runsUntilNextPassivation?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type GQLSystemCleanupLiveStatusUnitStatus = {
  percent?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type GQLSystemClusterStatus = {
  blockLabel?: Maybe<Scalars['String']>;
  clusterLabel?: Maybe<Scalars['String']>;
  currentStatus?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  testResult?: Maybe<Scalars['String']>;
  testTime?: Maybe<GQLClusterStatusTestTime>;
};

export type GQLSystemLiveKpi = {
  actualCol1Dp?: Maybe<Scalars['Float']>;
  actualCol2Dp?: Maybe<Scalars['Float']>;
  actualCol3Dp?: Maybe<Scalars['Float']>;
  actualDp?: Maybe<Scalars['Float']>;
  actualFlow?: Maybe<Scalars['Float']>;
  cipCyclesSinceLastRefill?: Maybe<Scalars['Float']>;
  efficiencyPct?: Maybe<Scalars['Float']>;
  finalFiltration?: Maybe<Scalars['Boolean']>;
  hlStablized?: Maybe<Scalars['Float']>;
  lastHourAverageFlow?: Maybe<Scalars['Float']>;
  lineOxygenPpb?: Maybe<Scalars['Float']>;
  lineTurbidity25Ebc?: Maybe<Scalars['Float']>;
  lineTurbidity90Ebc?: Maybe<Scalars['Float']>;
  lineTurbidityEbc?: Maybe<Scalars['Float']>;
  preFiltration?: Maybe<Scalars['Boolean']>;
  productionCipCycles?: Maybe<Scalars['Float']>;
  productionCumFlowHlh?: Maybe<Scalars['Float']>;
  productionCumThroughputHlM2?: Maybe<Scalars['Float']>;
  productionCumVolumeHl?: Maybe<Scalars['Float']>;
  productionNetCapacityLmh?: Maybe<Scalars['Float']>;
  throughputHlCassette?: Maybe<Scalars['Float']>;
  tmpBar?: Maybe<Scalars['Float']>;
};

export type GQLSystemLiveRegeneration = {
  cleanTypeCaustic?: Maybe<GQLSystemRegenerationCleanType>;
  cleanTypeEnzymatic?: Maybe<GQLSystemRegenerationCleanType>;
  cleanTypeSanitation?: Maybe<GQLSystemRegenerationCleanType>;
  phMeter?: Maybe<Scalars['Int']>;
  recentRunningStatus?: Maybe<Array<Maybe<GQLSystemRegenerationRunningStatus>>>;
};

export type GQLSystemLiveStatus = {
  currentSystemStatus?: Maybe<Scalars['String']>;
  diaFiltration?: Maybe<Scalars['Boolean']>;
  finalFiltration?: Maybe<Scalars['Boolean']>;
  installedClusters?: Maybe<Scalars['Int']>;
  lastAlarm?: Maybe<Scalars['String']>;
  lastServiceDate?: Maybe<Scalars['String']>;
  operationalClusters?: Maybe<Scalars['Int']>;
  preFiltration?: Maybe<Scalars['Boolean']>;
  processedVolumeHl?: Maybe<Scalars['Float']>;
  productionBrand?: Maybe<Scalars['String']>;
  productionPercentCompletePct?: Maybe<Scalars['Float']>;
  productionRemainingVolumeHl?: Maybe<Scalars['Float']>;
  productionStartTime?: Maybe<Scalars['String']>;
  productionTargetVolumeHl?: Maybe<Scalars['Float']>;
  recentSystemStatus?: Maybe<Array<Maybe<GQLSystemLiveStatusRecentSystem>>>;
  remainingTimeSeconds?: Maybe<Scalars['Int']>;
  serviceOverdue?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type GQLSystemLiveStatusRecentSystem = {
  percent?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type GQLSystemProvision = {
  blocks?: Maybe<GQLSystemProvisionBlock>;
  columns?: Maybe<Array<Maybe<GQLSystemProvisionColumn>>>;
};

export type GQLSystemProvisionBlock = {
  clusters?: Maybe<GQLSystemProvisionBlockCluster>;
  quantity?: Maybe<Scalars['Int']>;
};

export type GQLSystemProvisionBlockCluster = {
  consumables?: Maybe<GQLSystemProvisionBlockClusterConsumable>;
  modules?: Maybe<GQLSystemProvisionBlockClusterModule>;
  quantity?: Maybe<Scalars['Int']>;
};

export type GQLSystemProvisionBlockClusterConsumable = {
  installDate?: Maybe<Scalars['String']>;
  productNumber?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type GQLSystemProvisionBlockClusterModule = {
  partNumber?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type GQLSystemProvisionColumn = {
  cassettes?: Maybe<Scalars['Int']>;
  clusters?: Maybe<GQLSystemProvisionColumnCluster>;
  position?: Maybe<Scalars['Float']>;
};

export type GQLSystemProvisionColumnCluster = {
  quantity?: Maybe<Scalars['Int']>;
};

export type GQLSystemRegenerationCleanType = {
  lastClean?: Maybe<Scalars['String']>;
  processedVolumeHl?: Maybe<Scalars['Int']>;
};

export type GQLSystemRegenerationRunningStatus = {
  percent?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type GQLSystemStat = {
  compare?: Maybe<Array<Maybe<GQLSystemStatState>>>;
  current?: Maybe<Array<Maybe<GQLSystemStatState>>>;
  systemId?: Maybe<Scalars['ID']>;
};

export type GQLSystemStatState = {
  acidicChemical?: Maybe<Scalars['Float']>;
  acidicCost?: Maybe<Scalars['Float']>;
  actualStabilizationCost?: Maybe<Scalars['Float']>;
  averageFlow?: Maybe<Scalars['Float']>;
  averageTemperature?: Maybe<Scalars['Float']>;
  avgProcessedVolume?: Maybe<Scalars['Float']>;
  beerLossByFilterLoss?: Maybe<Scalars['Float']>;
  beerYield?: Maybe<Scalars['Float']>;
  capacityFullRunsTillTMPBar?: Maybe<Scalars['Float']>;
  caustic?: Maybe<Scalars['Float']>;
  causticCost?: Maybe<Scalars['Float']>;
  causticCycles?: Maybe<Scalars['Float']>;
  clusterTestFailurePct?: Maybe<Scalars['Float']>;
  coolingEnergy?: Maybe<Scalars['Float']>;
  daWater?: Maybe<Scalars['Float']>;
  degreeOfEfficiency?: Maybe<Scalars['Float']>;
  diaFiltrationYield?: Maybe<Scalars['Float']>;
  electrical?: Maybe<Scalars['Float']>;
  enzymaticConsumption?: Maybe<Scalars['Float']>;
  enzymaticCycles?: Maybe<Scalars['Float']>;
  filterLineUtilization?: Maybe<Scalars['Float']>;
  filtrationOperatedToFinalTMP?: Maybe<Scalars['Float']>;
  fluxRate?: Maybe<Scalars['Float']>;
  hazeCentrifugeOut?: Maybe<Scalars['Float']>;
  hazeFilterOut?: Maybe<Scalars['Float']>;
  kWHl?: Maybe<Scalars['Float']>;
  membraneCleaner?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['String']>;
  netCapacity?: Maybe<Scalars['Float']>;
  nitricAcid?: Maybe<Scalars['Float']>;
  oxygen?: Maybe<Scalars['Float']>;
  peroxyde?: Maybe<Scalars['Float']>;
  plato?: Maybe<Scalars['Float']>;
  sanitationCycles?: Maybe<Scalars['Float']>;
  tmpIncrease?: Maybe<Scalars['Float']>;
  totalCIPS?: Maybe<Scalars['Float']>;
  totalColdFilteredBeer?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalProcessingTime?: Maybe<Scalars['Float']>;
  totalRecoveredBeer?: Maybe<Scalars['Float']>;
  totalStabilizedBeer?: Maybe<Scalars['Float']>;
  volumeFiltered?: Maybe<Scalars['Float']>;
  water?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type GQLUser = {
  companyId?: Maybe<Scalars['String']>;
  companyInfo?: Maybe<GQLCompanyInfo>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  liveStatus?: Maybe<GQLUserLiveStatus>;
  phoneNumber?: Maybe<Scalars['String']>;
  roleInfo?: Maybe<GQLRoleInfo>;
  settingsInfo?: Maybe<GQLSettingsInfo>;
  status?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type GQLUserFavorites = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type GQLUserLiveStatus = {
  lastActivityRouteKey?: Maybe<Scalars['String']>;
  lastActivityTimestamp?: Maybe<Scalars['String']>;
};

export type GQLGetBeerBrandQueryVariables = Exact<{
  brandId: Scalars['ID'];
}>;

export type GQLGetBeerBrandQuery = {
  getBeerBrand?: {
    id: string;
    name?: string | null;
    companyId?: string | null;
    companySiteId?: string | null;
    rawMaterial?: string | null;
    plato?: number | null;
    abv?: number | null;
    fermented?: string | null;
    beerType?: string | null;
    sortOrder?: number | null;
    status?: string | null;
  } | null;
};

export type GQLGetBlockQueryVariables = Exact<{
  blockId: Scalars['ID'];
}>;

export type GQLGetBlockQuery = {
  getBlock?: {
    id: string;
    companyId?: string | null;
    companySiteId?: string | null;
    areaId?: string | null;
    systemId?: string | null;
    description?: string | null;
    sortOrder?: number | null;
    status?: string | null;
    liveKPI?: {
      flowHlH?: number | null;
      tmpBar?: number | null;
      estCapacityLm2?: number | null;
    } | null;
    liveStatus?: {
      sequenceDescription?: string | null;
      sequenceStatus?: string | null;
      sequenceStartTime?: string | null;
      sequenceEstEndTime?: string | null;
      degreeOfEfficiencyMebak?: number | null;
      timeToMaxdPSeconds?: number | null;
      calibrationStatus?: boolean | null;
    } | null;
  } | null;
};

export type GQLGetCompanyQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type GQLGetCompanyQuery = {
  getCompany?: {
    id: string;
    name?: string | null;
    status?: string | null;
    companySiteCount?: { count?: number | null } | null;
    companySystemsCount?: { count?: number | null } | null;
    address?: {
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country?: string | null;
    } | null;
    liveStatus?: {
      systems?: Array<{
        type?: string | null;
        count?: number | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GQLGetCompanySiteQueryVariables = Exact<{
  siteId: Scalars['ID'];
}>;

export type GQLGetCompanySiteQuery = {
  getCompanySite?: {
    id: string;
    name?: string | null;
    longitude?: string | null;
    latitude?: string | null;
    status?: string | null;
    groups?: Array<string | null> | null;
    companyId?: string | null;
    address?: {
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country?: string | null;
    } | null;
    liveKPI?: {
      efficiencyRank?: number | null;
      siteEfficiencyPct?: number | null;
      productionCumIdleStandbyTimeMinutes?: number | null;
      productionCumCleanTimeMinutes?: number | null;
      productionCumProductionTimeMinutes?: number | null;
    } | null;
    liveStatus?: {
      activeAlertCount?: number | null;
      systems?: Array<{
        type?: string | null;
        count?: number | null;
      } | null> | null;
    } | null;
    siteSystemsCount?: { count?: number | null } | null;
  } | null;
};

export type GQLGetFilterRunQueryVariables = Exact<{
  systemId: Scalars['ID'];
  filterRunId: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
}>;

export type GQLGetFilterRunQuery = {
  getFilterRunId?: Array<{
    filterRunId?: string | null;
    systemId?: string | null;
    brandName?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    totalRunTime?: number | null;
    volumeFiltered?: number | null;
    throughput?: number | null;
    netCapacity?: number | null;
    turbidity?: number | null;
    oxygen?: number | null;
    totalCIPS?: number | null;
    degreeOfEfficiency?: number | null;
    averageFlow?: number | null;
    fluxRate?: number | null;
  } | null> | null;
};

export type GQLGetSystemQueryVariables = Exact<{
  systemId: Scalars['ID'];
}>;

export type GQLGetSystemQuery = {
  getSystem?: {
    id: string;
    companyId?: string | null;
    companySiteId?: string | null;
    areaId?: string | null;
    name?: string | null;
    deploymentDate?: string | null;
    serialNumber?: string | null;
    modelNumber?: string | null;
    status?: string | null;
    type?: string | null;
    sortOrder?: number | null;
    liveKPI?: {
      efficiencyPct?: number | null;
      preFiltration?: boolean | null;
      finalFiltration?: boolean | null;
      productionCumVolumeHl?: number | null;
      productionCumThroughputHlM2?: number | null;
      productionNetCapacityLmh?: number | null;
      lineOxygenPpb?: number | null;
      throughputHlCassette?: number | null;
      hlStablized?: number | null;
      tmpBar?: number | null;
      cipCyclesSinceLastRefill?: number | null;
      productionCipCycles?: number | null;
      productionCumFlowHlh?: number | null;
      actualDp?: number | null;
      actualCol1Dp?: number | null;
      actualCol2Dp?: number | null;
      actualCol3Dp?: number | null;
      actualFlow?: number | null;
      lineTurbidityEbc?: number | null;
      lastHourAverageFlow?: number | null;
    } | null;
    liveStatus?: {
      currentSystemStatus?: string | null;
      productionStartTime?: string | null;
      processedVolumeHl?: number | null;
      lastAlarm?: string | null;
      lastServiceDate?: string | null;
      serviceOverdue?: number | null;
      preFiltration?: boolean | null;
      finalFiltration?: boolean | null;
      timestamp?: string | null;
      diaFiltration?: boolean | null;
      remainingTimeSeconds?: number | null;
      productionPercentCompletePct?: number | null;
      productionRemainingVolumeHl?: number | null;
      productionTargetVolumeHl?: number | null;
      operationalClusters?: number | null;
      installedClusters?: number | null;
      productionBrand?: string | null;
      recentSystemStatus?: Array<{
        status?: string | null;
        percent?: number | null;
      } | null> | null;
    } | null;
    clusterStatus?: Array<{
      id: string;
      clusterLabel?: string | null;
      blockLabel?: string | null;
      currentStatus?: string | null;
      testResult?: string | null;
      testTime?: { ScalarValue?: string | null } | null;
    } | null> | null;
    cleanUpLiveKPI?: {
      waterConsumptionM3Hl?: number | null;
      causticLow?: boolean | null;
      acidLow?: boolean | null;
      peroxideLow?: boolean | null;
      membraneCleanerLow?: boolean | null;
      timestamp?: string | null;
    } | null;
    cleanUpLiveStatus?: {
      currentCleanUpUnitStatus?: string | null;
      passivationStartTime?: string | null;
      productionsBeforePassivation?: number | null;
      nextpassivationStartTime?: string | null;
      runsUntilNextPassivation?: number | null;
      recentCleanUpUnitStatus?: Array<{
        status?: string | null;
        percent?: number | null;
      } | null> | null;
    } | null;
    liveRegeneration?: {
      phMeter?: number | null;
      recentRunningStatus?: Array<{
        status?: string | null;
        percent?: number | null;
      } | null> | null;
      cleanTypeSanitation?: {
        lastClean?: string | null;
        processedVolumeHl?: number | null;
      } | null;
      cleanTypeCaustic?: {
        lastClean?: string | null;
        processedVolumeHl?: number | null;
      } | null;
      cleanTypeEnzymatic?: {
        lastClean?: string | null;
        processedVolumeHl?: number | null;
      } | null;
    } | null;
  } | null;
};

export type GQLGetSystemStatsQueryVariables = Exact<{
  systemId: Scalars['ID'];
  parameter: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  compareStartTime: Scalars['String'];
  compareEndTime: Scalars['String'];
  interval: Scalars['String'];
  filterRunId?: InputMaybe<Scalars['String']>;
  compareFilterRunId?: InputMaybe<Scalars['String']>;
}>;

export type GQLGetSystemStatsQuery = {
  getSystemStats?: {
    current?: Array<{
      week?: string | null;
      month?: string | null;
      year?: string | null;
      volumeFiltered?: number | null;
      totalStabilizedBeer?: number | null;
      averageFlow?: number | null;
      degreeOfEfficiency?: number | null;
      tmpIncrease?: number | null;
      filtrationOperatedToFinalTMP?: number | null;
      capacityFullRunsTillTMPBar?: number | null;
      filterLineUtilization?: number | null;
      netCapacity?: number | null;
      totalCIPS?: number | null;
      beerLossByFilterLoss?: number | null;
      water?: number | null;
      caustic?: number | null;
      acidicChemical?: number | null;
      membraneCleaner?: number | null;
      kWHl?: number | null;
      oxygen?: number | null;
      hazeCentrifugeOut?: number | null;
      hazeFilterOut?: number | null;
      clusterTestFailurePct?: number | null;
      peroxyde?: number | null;
      electrical?: number | null;
      daWater?: number | null;
      actualStabilizationCost?: number | null;
      nitricAcid?: number | null;
      coolingEnergy?: number | null;
      fluxRate?: number | null;
      plato?: number | null;
      averageTemperature?: number | null;
      totalRecoveredBeer?: number | null;
      totalColdFilteredBeer?: number | null;
      beerYield?: number | null;
      diaFiltrationYield?: number | null;
      causticCycles?: number | null;
      causticCost?: number | null;
      acidicCost?: number | null;
      sanitationCycles?: number | null;
      enzymaticCycles?: number | null;
      enzymaticConsumption?: number | null;
      totalCost?: number | null;
      totalProcessingTime?: number | null;
      avgProcessedVolume?: number | null;
    } | null> | null;
    compare?: Array<{
      week?: string | null;
      month?: string | null;
      year?: string | null;
      volumeFiltered?: number | null;
      totalStabilizedBeer?: number | null;
      averageFlow?: number | null;
      degreeOfEfficiency?: number | null;
      tmpIncrease?: number | null;
      filtrationOperatedToFinalTMP?: number | null;
      capacityFullRunsTillTMPBar?: number | null;
      filterLineUtilization?: number | null;
      netCapacity?: number | null;
      totalCIPS?: number | null;
      beerLossByFilterLoss?: number | null;
      water?: number | null;
      caustic?: number | null;
      acidicChemical?: number | null;
      membraneCleaner?: number | null;
      kWHl?: number | null;
      oxygen?: number | null;
      hazeCentrifugeOut?: number | null;
      hazeFilterOut?: number | null;
      clusterTestFailurePct?: number | null;
      peroxyde?: number | null;
      electrical?: number | null;
      daWater?: number | null;
      actualStabilizationCost?: number | null;
      nitricAcid?: number | null;
      coolingEnergy?: number | null;
      fluxRate?: number | null;
      plato?: number | null;
      averageTemperature?: number | null;
      totalRecoveredBeer?: number | null;
      totalColdFilteredBeer?: number | null;
      beerYield?: number | null;
      diaFiltrationYield?: number | null;
      causticCycles?: number | null;
      causticCost?: number | null;
      acidicCost?: number | null;
      sanitationCycles?: number | null;
      enzymaticCycles?: number | null;
      enzymaticConsumption?: number | null;
      totalCost?: number | null;
      totalProcessingTime?: number | null;
      avgProcessedVolume?: number | null;
    } | null> | null;
  } | null;
};

export type GQLGetUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type GQLGetUserQuery = {
  getUser?: {
    userId?: string | null;
    companyId?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    status?: string | null;
    sub?: string | null;
    liveStatus?: {
      lastActivityRouteKey?: string | null;
      lastActivityTimestamp?: string | null;
    } | null;
    settingsInfo?: GQLSettingsInfo | null;
  } | null;
};

export type GQLListAlertsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
  alertType?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListAlertsQuery = {
  listActiveAlerts?: Array<{
    companyId?: string | null;
    siteId?: string | null;
    measure_name?: string | null;
    alertState?: boolean | null;
    time?: string | null;
    thumbprint?: string | null;
    alertType?: string | null;
  } | null> | null;
};

export type GQLListAlertsHistoricalQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
  alertType?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListAlertsHistoricalQuery = {
  listAlertsHistory?: Array<{
    companyId?: string | null;
    siteId?: string | null;
    measure_name?: string | null;
    alertState?: boolean | null;
    alertType?: string | null;
    time?: string | null;
    thumbprint?: string | null;
    userSub?: string | null;
    userFirstName?: string | null;
    userLastName?: string | null;
    userEmailAddress?: string | null;
    dismissalDatetime?: string | null;
  } | null> | null;
};

export type GQLListBeerBrandsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListBeerBrandsQuery = {
  listBeerBrands?: Array<{
    id: string;
    name?: string | null;
    companyId?: string | null;
    companySiteId?: string | null;
    rawMaterial?: string | null;
    plato?: number | null;
    abv?: number | null;
    fermented?: string | null;
    beerType?: string | null;
    sortOrder?: number | null;
    status?: string | null;
  } | null> | null;
};

export type GQLListBlocksQueryVariables = Exact<{
  systemId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListBlocksQuery = {
  listBlocks?: Array<{
    id: string;
    companyId?: string | null;
    companySiteId?: string | null;
    areaId?: string | null;
    systemId?: string | null;
    description?: string | null;
    sortOrder?: number | null;
    status?: string | null;
    liveKPI?: {
      flowHlH?: number | null;
      tmpBar?: number | null;
      estCapacityLm2?: number | null;
    } | null;
    liveStatus?: {
      sequenceDescription?: string | null;
      sequenceStatus?: string | null;
      sequenceStartTime?: string | null;
      sequenceEstEndTime?: string | null;
      degreeOfEfficiencyMebak?: number | null;
      timeToMaxdPSeconds?: number | null;
      calibrationStatus?: boolean | null;
      recentRunningStatus?: Array<{
        status?: string | null;
        percent?: number | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GQLListClustersQueryVariables = Exact<{
  systemId?: InputMaybe<Scalars['String']>;
  blockId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListClustersQuery = {
  listClusters?: Array<{
    id: string;
    companyId?: string | null;
    companySiteId?: string | null;
    areaId?: string | null;
    systemId?: string | null;
    blockId?: string | null;
    description?: string | null;
    calibrationStatus?: string | null;
    testResult?: string | null;
    sortOrder?: number | null;
    status?: string | null;
    liveStatus?: {
      currentStatus?: string | null;
      testResult?: string | null;
    } | null;
    integrityStatus?: {
      testResult?: string | null;
      timestamp?: string | null;
    } | null;
  } | null> | null;
};

export type GQLListColumnsQueryVariables = Exact<{
  systemId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListColumnsQuery = {
  listColumns?: Array<{
    id: string;
    companyId?: string | null;
    companySiteId?: string | null;
    areaId?: string | null;
    systemId?: string | null;
    name?: string | null;
    cassettes?: number | null;
    sortOrder?: number | null;
    status?: string | null;
    columnRecentRunningStatus?: {
      currentColumnStatus?: string | null;
      actualDp?: { dP?: number | null } | null;
      recentRunningStatus?: Array<{
        percent?: number | null;
        status?: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GQLListCompaniesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListCompaniesQuery = {
  listCompanies?: Array<{
    id: string;
    name?: string | null;
    status?: string | null;
    companySiteCount?: { count?: number | null } | null;
    companySystemsCount?: { count?: number | null } | null;
    address?: {
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country?: string | null;
    } | null;
    liveStatus?: {
      systems?: Array<{
        type?: string | null;
        count?: number | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GQLListCompanySitesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListCompanySitesQuery = {
  listCompanySites?: Array<{
    id: string;
    name?: string | null;
    longitude?: string | null;
    latitude?: string | null;
    status?: string | null;
    groups?: Array<string | null> | null;
    companyId?: string | null;
    address?: {
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      state?: string | null;
      zipcode?: string | null;
      country?: string | null;
    } | null;
    liveKPI?: {
      efficiencyRank?: number | null;
      siteEfficiencyPct?: number | null;
      productionCumIdleStandbyTimeMinutes?: number | null;
      productionCumCleanTimeMinutes?: number | null;
      productionCumProductionTimeMinutes?: number | null;
    } | null;
    liveStatus?: {
      activeAlertCount?: number | null;
      systems?: Array<{
        type?: string | null;
        count?: number | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GQLListConsumablesQueryVariables = Exact<{
  systemId?: InputMaybe<Scalars['String']>;
  blockId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListConsumablesQuery = {
  listConsumables?: Array<{
    id: string;
    companyId?: string | null;
    companySiteId?: string | null;
    areaId?: string | null;
    systemId?: string | null;
    blockId?: string | null;
    productNumber?: string | null;
    status?: string | null;
    liveKPI?: {
      totalFilteredL?: number | null;
      totalCipCycles?: number | null;
    } | null;
    liveStatus?: {
      moduleId?: string | null;
      serialNumber?: string | null;
      placeNumber?: number | null;
      clusterNumber?: number | null;
      moduleNumber?: number | null;
      blockNumber?: number | null;
      installationTime?: string | null;
      pinCount?: number | null;
      pins: Array<{ key?: string | null; value?: string | null }>;
    } | null;
  } | null> | null;
};

export type GQLListFilterRunsQueryVariables = Exact<{
  systemId: Scalars['ID'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
}>;

export type GQLListFilterRunsQuery = {
  listFilterRuns?: Array<{
    filterRunId?: string | null;
    systemId?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    volumeFiltered?: number | null;
    brandName?: string | null;
    totalRecoveredBeer?: number | null;
  } | null> | null;
};

export type GQLListReportDefinitionsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListReportDefinitionsQuery = {
  listReportDefinitions?: Array<{
    id: string;
    reportName?: string | null;
    reportType?: string | null;
    frequency?: string | null;
    companyId?: string | null;
    aggregation?: string | null;
    systems?: Array<{
      companySiteId?: string | null;
      systemId?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GQLListReportsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  systemId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListReportsQuery = {
  listReports?: Array<{
    id: string;
    name?: string | null;
    type?: string | null;
    frequency?: string | null;
    startDate?: string | null;
    endDate?: string | null;
    aggregation?: string | null;
    companyId?: string | null;
    createdOn?: string | null;
    createdBy?: string | null;
    generatedOn?: string | null;
    reportS3Key?: string | null;
    status?: string | null;
    statusLastUpdate?: string | null;
    systems?: Array<{
      companySiteId?: string | null;
      systemId?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GQLListSystemsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
  companySiteId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GQLListSystemsQuery = {
  listSystems?: Array<{
    id: string;
    companyId?: string | null;
    companySiteId?: string | null;
    areaId?: string | null;
    name?: string | null;
    deploymentDate?: string | null;
    serialNumber?: string | null;
    modelNumber?: string | null;
    status?: string | null;
    type?: string | null;
    sortOrder?: number | null;
    liveKPI?: {
      efficiencyPct?: number | null;
      throughputHlCassette?: number | null;
      hlStablized?: number | null;
      tmpBar?: number | null;
      cipCyclesSinceLastRefill?: number | null;
      productionCipCycles?: number | null;
      productionCumThroughputHlM2?: number | null;
      productionCumVolumeHl?: number | null;
      productionCumFlowHlh?: number | null;
      productionNetCapacityLmh?: number | null;
      actualDp?: number | null;
      actualFlow?: number | null;
      lineTurbidityEbc?: number | null;
      lineOxygenPpb?: number | null;
      lastHourAverageFlow?: number | null;
    } | null;
    liveRegeneration?: Maybe<GQLSystemLiveRegeneration>;
    liveStatus?: {
      currentSystemStatus?: string | null;
      productionBrand?: string | null;
      installedClusters?: number | null;
      operationalClusters?: number | null;
      productionStartTime?: string | null;
      productionTargetVolumeHl?: number | null;
      productionRemainingVolumeHl?: number | null;
      processedVolumeHl?: number | null;
      productionPercentCompletePct?: number | null;
      remainingTimeSeconds?: number | null;
      lastAlarm?: string | null;
      serviceOverdue?: number | null;
      diaFiltration?: boolean | null;
      recentSystemStatus?: Array<{
        status?: string | null;
        percent?: number | null;
      } | null> | null;
    } | null;
    clusterStatus?: Array<{
      id: string;
      clusterLabel?: string | null;
      blockLabel?: string | null;
      currentStatus?: string | null;
      testResult?: string | null;
      testTime?: { ScalarValue?: string | null } | null;
    } | null> | null;
    cleanUpLiveKPI?: {
      waterConsumptionM3Hl?: number | null;
      causticLow?: boolean | null;
      acidLow?: boolean | null;
      peroxideLow?: boolean | null;
      membraneCleanerLow?: boolean | null;
      timestamp?: string | null;
    } | null;
    cleanUpLiveStatus?: {
      currentCleanUpUnitStatus?: string | null;
      recentCleanUpUnitStatus?: Array<{
        status?: string | null;
        percent?: number | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GQLListUsersQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
}>;

export type GQLListUsersQuery = {
  listUsers?: Array<{
    userId?: string | null;
    companyId?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    status?: string | null;
    sub?: string | null;
    liveStatus?: {
      lastActivityRouteKey?: string | null;
      lastActivityTimestamp?: string | null;
    } | null;
  } | null> | null;
};

export const GetBeerBrandDocument = `
    query getBeerBrand($brandId: ID!) {
  getBeerBrand(id: $brandId) {
    id
    name
    companyId
    companySiteId
    rawMaterial
    plato
    abv
    fermented
    beerType
    sortOrder
    status
  }
}
    `;
export const useGetBeerBrandQuery = <
  TData = GQLGetBeerBrandQuery,
  TError = unknown
>(
  variables: GQLGetBeerBrandQueryVariables,
  options?: UseQueryOptions<GQLGetBeerBrandQuery, TError, TData>
) =>
  useQuery<GQLGetBeerBrandQuery, TError, TData>(
    ['getBeerBrand', variables],
    axiosHelper<GQLGetBeerBrandQuery, GQLGetBeerBrandQueryVariables>(
      GetBeerBrandDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteGetBeerBrandQuery = <
  TData = GQLGetBeerBrandQuery,
  TError = unknown
>(
  variables: GQLGetBeerBrandQueryVariables,
  options?: UseInfiniteQueryOptions<GQLGetBeerBrandQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLGetBeerBrandQuery,
    GQLGetBeerBrandQueryVariables
  >(GetBeerBrandDocument);
  return useInfiniteQuery<GQLGetBeerBrandQuery, TError, TData>(
    ['getBeerBrand.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const GetBlockDocument = `
    query getBlock($blockId: ID!) {
  getBlock(id: $blockId) {
    id
    companyId
    companySiteId
    areaId
    systemId
    description
    sortOrder
    status
    liveKPI {
      flowHlH
      tmpBar
      estCapacityLm2
    }
    liveStatus {
      sequenceDescription
      sequenceStatus
      sequenceStartTime
      sequenceEstEndTime
      degreeOfEfficiencyMebak
      timeToMaxdPSeconds
      calibrationStatus
    }
  }
}
    `;
export const useGetBlockQuery = <TData = GQLGetBlockQuery, TError = unknown>(
  variables: GQLGetBlockQueryVariables,
  options?: UseQueryOptions<GQLGetBlockQuery, TError, TData>
) =>
  useQuery<GQLGetBlockQuery, TError, TData>(
    ['getBlock', variables],
    axiosHelper<GQLGetBlockQuery, GQLGetBlockQueryVariables>(
      GetBlockDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteGetBlockQuery = <
  TData = GQLGetBlockQuery,
  TError = unknown
>(
  variables: GQLGetBlockQueryVariables,
  options?: UseInfiniteQueryOptions<GQLGetBlockQuery, TError, TData>
) => {
  const query = axiosHelper<GQLGetBlockQuery, GQLGetBlockQueryVariables>(
    GetBlockDocument
  );
  return useInfiniteQuery<GQLGetBlockQuery, TError, TData>(
    ['getBlock.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const GetCompanyDocument = `
    query getCompany($companyId: ID!) {
  getCompany(id: $companyId) {
    id
    name
    companySiteCount {
      count
    }
    companySystemsCount {
      profi
      cbs
      cfs
      keraflux
      count
    }
    address {
      address1
      address2
      city
      state
      zipcode
      country
    }
    status
    liveStatus {
      systems {
        type
        count
      }
    }
  }
}
    `;
export const useGetCompanyQuery = <
  TData = GQLGetCompanyQuery,
  TError = unknown
>(
  variables: GQLGetCompanyQueryVariables,
  options?: UseQueryOptions<GQLGetCompanyQuery, TError, TData>
) =>
  useQuery<GQLGetCompanyQuery, TError, TData>(
    ['getCompany', variables],
    axiosHelper<GQLGetCompanyQuery, GQLGetCompanyQueryVariables>(
      GetCompanyDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteGetCompanyQuery = <
  TData = GQLGetCompanyQuery,
  TError = unknown
>(
  variables: GQLGetCompanyQueryVariables,
  options?: UseInfiniteQueryOptions<GQLGetCompanyQuery, TError, TData>
) => {
  const query = axiosHelper<GQLGetCompanyQuery, GQLGetCompanyQueryVariables>(
    GetCompanyDocument
  );
  return useInfiniteQuery<GQLGetCompanyQuery, TError, TData>(
    ['getCompany.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const GetCompanySiteDocument = `
    query getCompanySite($siteId: ID!) {
  getCompanySite(id: $siteId) {
    address {
      address1
      address2
      city
      state
      zipcode
      country
    }
    id
    name
    longitude
    latitude
    status
    groups
    companyId
    liveKPI {
      efficiencyRank
      siteEfficiencyPct
      productionCumIdleStandbyTimeMinutes
      productionCumCleanTimeMinutes
      productionCumProductionTimeMinutes
    }
    liveStatus {
      activeAlertCount
      systems {
        type
        count
      }
    }
    siteSystemsCount {
      profi
      cbs
      cfs
      keraflux
      count
    }
  }
}
    `;
export const useGetCompanySiteQuery = <
  TData = GQLGetCompanySiteQuery,
  TError = unknown
>(
  variables: GQLGetCompanySiteQueryVariables,
  options?: UseQueryOptions<GQLGetCompanySiteQuery, TError, TData>
) =>
  useQuery<GQLGetCompanySiteQuery, TError, TData>(
    ['getCompanySite', variables],
    axiosHelper<GQLGetCompanySiteQuery, GQLGetCompanySiteQueryVariables>(
      GetCompanySiteDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteGetCompanySiteQuery = <
  TData = GQLGetCompanySiteQuery,
  TError = unknown
>(
  variables: GQLGetCompanySiteQueryVariables,
  options?: UseInfiniteQueryOptions<GQLGetCompanySiteQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLGetCompanySiteQuery,
    GQLGetCompanySiteQueryVariables
  >(GetCompanySiteDocument);
  return useInfiniteQuery<GQLGetCompanySiteQuery, TError, TData>(
    ['getCompanySite.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const GetFilterRunDocument = `
    query getFilterRun($systemId: ID!, $filterRunId: String!, $startTime: String!, $endTime: String!) {
  getFilterRunId(
    id: $systemId
    filterRunId: $filterRunId
    startTime: $startTime
    endTime: $endTime
  ) {
    filterRunId
    systemId
    brandName
    startTime
    endTime
    totalRunTime
    volumeFiltered
    throughput
    netCapacity
    turbidity
    oxygen
    totalCIPS
    degreeOfEfficiency
    averageFlow
    fluxRate
  }
}
    `;
export const useGetFilterRunQuery = <
  TData = GQLGetFilterRunQuery,
  TError = unknown
>(
  variables: GQLGetFilterRunQueryVariables,
  options?: UseQueryOptions<GQLGetFilterRunQuery, TError, TData>
) =>
  useQuery<GQLGetFilterRunQuery, TError, TData>(
    ['getFilterRun', variables],
    axiosHelper<GQLGetFilterRunQuery, GQLGetFilterRunQueryVariables>(
      GetFilterRunDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteGetFilterRunQuery = <
  TData = GQLGetFilterRunQuery,
  TError = unknown
>(
  variables: GQLGetFilterRunQueryVariables,
  options?: UseInfiniteQueryOptions<GQLGetFilterRunQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLGetFilterRunQuery,
    GQLGetFilterRunQueryVariables
  >(GetFilterRunDocument);
  return useInfiniteQuery<GQLGetFilterRunQuery, TError, TData>(
    ['getFilterRun.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const GetSystemDocument = `
    query getSystem($systemId: ID!) {
  getSystem(id: $systemId) {
    id
    companyId
    companySiteId
    areaId
    name
    deploymentDate
    serialNumber
    modelNumber
    status
    type
    liveKPI {
      efficiencyPct
      preFiltration
      finalFiltration
      productionCumVolumeHl
      productionCumThroughputHlM2
      productionNetCapacityLmh
      lineOxygenPpb
      throughputHlCassette
      hlStablized
      tmpBar
      cipCyclesSinceLastRefill
      productionCipCycles
      productionCumFlowHlh
      actualDp
      actualCol1Dp
      actualCol2Dp
      actualCol3Dp
      actualFlow
      lineTurbidityEbc
      lastHourAverageFlow
    }
    liveStatus {
      recentSystemStatus {
        status
        percent
      }
      currentSystemStatus
      productionStartTime
      processedVolumeHl
      lastAlarm
      lastServiceDate
      serviceOverdue
      preFiltration
      finalFiltration
      timestamp
      diaFiltration
      remainingTimeSeconds
      productionPercentCompletePct
      productionRemainingVolumeHl
      productionTargetVolumeHl
      operationalClusters
      installedClusters
      productionBrand
    }
    clusterStatus {
      id
      clusterLabel
      blockLabel
      currentStatus
      testResult
      testTime {
        ScalarValue
      }
    }
    cleanUpLiveKPI {
      waterConsumptionM3Hl
      causticLow
      acidLow
      peroxideLow
      membraneCleanerLow
      timestamp
    }
    cleanUpLiveStatus {
      recentCleanUpUnitStatus {
        status
        percent
      }
      currentCleanUpUnitStatus
      passivationStartTime
      productionsBeforePassivation
      nextpassivationStartTime
      runsUntilNextPassivation
    }
    sortOrder
    liveRegeneration {
      phMeter
      recentRunningStatus {
        status
        percent
      }
      cleanTypeSanitation {
        lastClean
        processedVolumeHl
      }
      cleanTypeCaustic {
        lastClean
        processedVolumeHl
      }
      cleanTypeEnzymatic {
        lastClean
        processedVolumeHl
      }
    }

systemSettingsInfo {
  consumption {
      acidicChemicalL
      airKg
      beerLostL
      causticKg
      cipEnergykWh
      co2Kg
      daWater
      dawCount
      enzymaticChemicalL
      membraneCleanerKg
      nitricAcidicChemicalL
      peroxideL
      platoDegree
      stablizationConsthL
      waterL
  }
  every10thCycle {
      acidicChemicalL
  }
  thresholdValues {
      targetFlowRateHlh
  }
  degreeOfEfficiency {
      green
      red
      yellow
  }
  oxygen {
      green
      red
      yellow
  }
  netCapacity {
      green
      red
      yellow
  }
  waterHl {
      green
      red
      yellow
  }
  daWaterHl {
      green
      red
      yellow
  }
  opex {
      green
      red
      yellow
  }
  peroxyde {
      green
      red
      yellow
  }
  membraneCleaner {
      green
      red
      yellow
  }
  caustic {
      green
      red
      yellow
  }
  concentrateStatus {
      green
      red
      yellow
  }
  
  actualFlow {
      green
      red
      yellow
  }
  actualDp {
      green
      red
      yellow
  }
  cluster {
      green
      red
      yellow
  }
  turbidityEbc {
      green
      red
      yellow
  }
  operationalCluster {
      green
      red
      yellow
  }
  serviceSettings {
      profi { 
          value 
          unitOfMeasure
      }
      cbs { 
          value 
          unitOfMeasure
      }
      cfs { 
          value 
          unitOfMeasure
      }
      keraflux { 
          value 
          unitOfMeasure
      }
  }
}
  }
}
    `;
export const useGetSystemQuery = <TData = GQLGetSystemQuery, TError = unknown>(
  variables: GQLGetSystemQueryVariables,
  options?: UseQueryOptions<GQLGetSystemQuery, TError, TData>
) =>
  useQuery<GQLGetSystemQuery, TError, TData>(
    ['getSystem', variables],
    axiosHelper<GQLGetSystemQuery, GQLGetSystemQueryVariables>(
      GetSystemDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteGetSystemQuery = <
  TData = GQLGetSystemQuery,
  TError = unknown
>(
  variables: GQLGetSystemQueryVariables,
  options?: UseInfiniteQueryOptions<GQLGetSystemQuery, TError, TData>
) => {
  const query = axiosHelper<GQLGetSystemQuery, GQLGetSystemQueryVariables>(
    GetSystemDocument
  );
  return useInfiniteQuery<GQLGetSystemQuery, TError, TData>(
    ['getSystem.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const GetSystemStatsDocument = `
    query getSystemStats($systemId: ID!, $parameter: String!, $startTime: String!, $endTime: String!, $compareStartTime: String!, $compareEndTime: String!, $interval: String!, $filterRunId: String, $compareFilterRunId: String) {
  getSystemStats(
    id: $systemId
    parameter: $parameter
    startTime: $startTime
    endTime: $endTime
    compareStartTime: $compareStartTime
    compareEndTime: $compareEndTime
    interval: $interval
    filterRunId: $filterRunId
    compareFilterRunId: $compareFilterRunId
  ) {
    current {
      week
      month
      year
      volumeFiltered
      totalStabilizedBeer
      averageFlow
      degreeOfEfficiency
      tmpIncrease
      filtrationOperatedToFinalTMP
      capacityFullRunsTillTMPBar
      filterLineUtilization
      netCapacity
      totalCIPS
      beerLossByFilterLoss
      water
      caustic
      acidicChemical
      membraneCleaner
      kWHl
      oxygen
      hazeCentrifugeOut
      hazeFilterOut
      clusterTestFailurePct
      peroxyde
      electrical
      daWater
      actualStabilizationCost
      nitricAcid
      coolingEnergy
      fluxRate
      plato
      averageTemperature
      totalRecoveredBeer
      totalColdFilteredBeer
      beerYield
      diaFiltrationYield
      causticCycles
      causticCost
      acidicCost
      sanitationCycles
      enzymaticCycles
      enzymaticConsumption
      totalCost
      totalProcessingTime
      avgProcessedVolume
    }
    compare {
      week
      month
      year
      volumeFiltered
      totalStabilizedBeer
      averageFlow
      degreeOfEfficiency
      tmpIncrease
      filtrationOperatedToFinalTMP
      capacityFullRunsTillTMPBar
      filterLineUtilization
      netCapacity
      totalCIPS
      beerLossByFilterLoss
      water
      caustic
      acidicChemical
      membraneCleaner
      kWHl
      oxygen
      hazeCentrifugeOut
      hazeFilterOut
      clusterTestFailurePct
      peroxyde
      electrical
      daWater
      actualStabilizationCost
      nitricAcid
      coolingEnergy
      fluxRate
      plato
      averageTemperature
      totalRecoveredBeer
      totalColdFilteredBeer
      beerYield
      diaFiltrationYield
      causticCycles
      causticCost
      acidicCost
      sanitationCycles
      enzymaticCycles
      enzymaticConsumption
      totalCost
      totalProcessingTime
      avgProcessedVolume
    }
  }
}
    `;
export const useGetSystemStatsQuery = <
  TData = GQLGetSystemStatsQuery,
  TError = unknown
>(
  variables: GQLGetSystemStatsQueryVariables,
  options?: UseQueryOptions<GQLGetSystemStatsQuery, TError, TData>
) =>
  useQuery<GQLGetSystemStatsQuery, TError, TData>(
    ['getSystemStats', variables],
    axiosHelper<GQLGetSystemStatsQuery, GQLGetSystemStatsQueryVariables>(
      GetSystemStatsDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteGetSystemStatsQuery = <
  TData = GQLGetSystemStatsQuery,
  TError = unknown
>(
  variables: GQLGetSystemStatsQueryVariables,
  options?: UseInfiniteQueryOptions<GQLGetSystemStatsQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLGetSystemStatsQuery,
    GQLGetSystemStatsQueryVariables
  >(GetSystemStatsDocument);
  return useInfiniteQuery<GQLGetSystemStatsQuery, TError, TData>(
    ['getSystemStats.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const GetUserDocument = `
    query getUser($userId: ID!) {
  getUser(id: $userId) {
    userId
    companyId
    email
    firstName
    lastName
    phoneNumber
    status
    sub
    liveStatus {
      lastActivityRouteKey
      lastActivityTimestamp
    }
    settingsInfo { 
      dateFormat
  language
  monthFormat
  pressureUnits
  volumeUnits
    }
  }
}
    `;
export const useGetUserQuery = <TData = GQLGetUserQuery, TError = unknown>(
  variables: GQLGetUserQueryVariables,
  options?: UseQueryOptions<GQLGetUserQuery, TError, TData>
) =>
  useQuery<GQLGetUserQuery, TError, TData>(
    ['getUser', variables],
    axiosHelper<GQLGetUserQuery, GQLGetUserQueryVariables>(
      GetUserDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteGetUserQuery = <
  TData = GQLGetUserQuery,
  TError = unknown
>(
  variables: GQLGetUserQueryVariables,
  options?: UseInfiniteQueryOptions<GQLGetUserQuery, TError, TData>
) => {
  const query = axiosHelper<GQLGetUserQuery, GQLGetUserQueryVariables>(
    GetUserDocument
  );
  return useInfiniteQuery<GQLGetUserQuery, TError, TData>(
    ['getUser.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListAlertsDocument = `
    query listAlerts($companyId: String, $siteId: String, $systemId: String, $alertType: String, $fromDate: String, $toDate: String, $skip: Int, $limit: Int) {
  listActiveAlerts(
    companyId: $companyId
    siteId: $siteId
    systemId: $systemId
    alertType: $alertType
    fromDate: $fromDate
    toDate: $toDate
    skip: $skip
    limit: $limit
  ) {
    companyId
    siteId
    measure_name
    alertState
    time
    thumbprint
    alertType
  }
}
    `;
export const useListAlertsQuery = <
  TData = GQLListAlertsQuery,
  TError = unknown
>(
  variables?: GQLListAlertsQueryVariables,
  options?: UseQueryOptions<GQLListAlertsQuery, TError, TData>
) =>
  useQuery<GQLListAlertsQuery, TError, TData>(
    variables === undefined ? ['listAlerts'] : ['listAlerts', variables],
    axiosHelper<GQLListAlertsQuery, GQLListAlertsQueryVariables>(
      ListAlertsDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListAlertsQuery = <
  TData = GQLListAlertsQuery,
  TError = unknown
>(
  variables?: GQLListAlertsQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListAlertsQuery, TError, TData>
) => {
  const query = axiosHelper<GQLListAlertsQuery, GQLListAlertsQueryVariables>(
    ListAlertsDocument
  );
  return useInfiniteQuery<GQLListAlertsQuery, TError, TData>(
    variables === undefined
      ? ['listAlerts.infinite']
      : ['listAlerts.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListAlertsHistoricalDocument = `
    query listAlertsHistorical($companyId: String, $siteId: String, $systemId: String, $alertType: String, $fromDate: String, $toDate: String, $skip: Int, $limit: Int) {
  listAlertsHistory(
    companyId: $companyId
    siteId: $siteId
    systemId: $systemId
    alertType: $alertType
    fromDate: $fromDate
    toDate: $toDate
    skip: $skip
    limit: $limit
  ) {
    companyId
    siteId
    measure_name
    alertState
    alertType
    time
    thumbprint
    userSub
    userFirstName
    userLastName
    userEmailAddress
    dismissalDatetime
  }
}
    `;
export const useListAlertsHistoricalQuery = <
  TData = GQLListAlertsHistoricalQuery,
  TError = unknown
>(
  variables?: GQLListAlertsHistoricalQueryVariables,
  options?: UseQueryOptions<GQLListAlertsHistoricalQuery, TError, TData>
) =>
  useQuery<GQLListAlertsHistoricalQuery, TError, TData>(
    variables === undefined
      ? ['listAlertsHistorical']
      : ['listAlertsHistorical', variables],
    axiosHelper<
      GQLListAlertsHistoricalQuery,
      GQLListAlertsHistoricalQueryVariables
    >(ListAlertsHistoricalDocument).bind(null, variables),
    options
  );
export const useInfiniteListAlertsHistoricalQuery = <
  TData = GQLListAlertsHistoricalQuery,
  TError = unknown
>(
  variables?: GQLListAlertsHistoricalQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListAlertsHistoricalQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLListAlertsHistoricalQuery,
    GQLListAlertsHistoricalQueryVariables
  >(ListAlertsHistoricalDocument);
  return useInfiniteQuery<GQLListAlertsHistoricalQuery, TError, TData>(
    variables === undefined
      ? ['listAlertsHistorical.infinite']
      : ['listAlertsHistorical.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListBeerBrandsDocument = `
    query listBeerBrands($companyId: String, $status: String, $skip: Int, $limit: Int) {
  listBeerBrands(
    companyId: $companyId
    status: $status
    skip: $skip
    limit: $limit
  ) {
    id
    name
    companyId
    companySiteId
    rawMaterial
    plato
    abv
    fermented
    beerType
    sortOrder
    status
  }
}
    `;
export const useListBeerBrandsQuery = <
  TData = GQLListBeerBrandsQuery,
  TError = unknown
>(
  variables?: GQLListBeerBrandsQueryVariables,
  options?: UseQueryOptions<GQLListBeerBrandsQuery, TError, TData>
) =>
  useQuery<GQLListBeerBrandsQuery, TError, TData>(
    variables === undefined
      ? ['listBeerBrands']
      : ['listBeerBrands', variables],
    axiosHelper<GQLListBeerBrandsQuery, GQLListBeerBrandsQueryVariables>(
      ListBeerBrandsDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListBeerBrandsQuery = <
  TData = GQLListBeerBrandsQuery,
  TError = unknown
>(
  variables?: GQLListBeerBrandsQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListBeerBrandsQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLListBeerBrandsQuery,
    GQLListBeerBrandsQueryVariables
  >(ListBeerBrandsDocument);
  return useInfiniteQuery<GQLListBeerBrandsQuery, TError, TData>(
    variables === undefined
      ? ['listBeerBrands.infinite']
      : ['listBeerBrands.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListBlocksDocument = `
    query listBlocks($systemId: String, $status: String, $skip: Int, $limit: Int) {
  listBlocks(systemId: $systemId, status: $status, skip: $skip, limit: $limit) {
    id
    companyId
    companySiteId
    areaId
    systemId
    description
    sortOrder
    status
    liveKPI {
      flowHlH
      tmpBar
      estCapacityLm2
    }
    liveStatus {
      recentRunningStatus {
        status
        percent
      }
      sequenceDescription
      sequenceStatus
      sequenceStartTime
      sequenceEstEndTime
      degreeOfEfficiencyMebak
      timeToMaxdPSeconds
      calibrationStatus
    }
  }
}
    `;
export const useListBlocksQuery = <
  TData = GQLListBlocksQuery,
  TError = unknown
>(
  variables?: GQLListBlocksQueryVariables,
  options?: UseQueryOptions<GQLListBlocksQuery, TError, TData>
) =>
  useQuery<GQLListBlocksQuery, TError, TData>(
    variables === undefined ? ['listBlocks'] : ['listBlocks', variables],
    axiosHelper<GQLListBlocksQuery, GQLListBlocksQueryVariables>(
      ListBlocksDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListBlocksQuery = <
  TData = GQLListBlocksQuery,
  TError = unknown
>(
  variables?: GQLListBlocksQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListBlocksQuery, TError, TData>
) => {
  const query = axiosHelper<GQLListBlocksQuery, GQLListBlocksQueryVariables>(
    ListBlocksDocument
  );
  return useInfiniteQuery<GQLListBlocksQuery, TError, TData>(
    variables === undefined
      ? ['listBlocks.infinite']
      : ['listBlocks.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListClustersDocument = `
    query listClusters($systemId: String, $blockId: String, $status: String, $skip: Int, $limit: Int) {
  listClusters(
    systemId: $systemId
    blockId: $blockId
    status: $status
    skip: $skip
    limit: $limit
  ) {
    id
    companyId
    companySiteId
    areaId
    systemId
    blockId
    description
    calibrationStatus
    testResult
    sortOrder
    status
    liveStatus {
      currentStatus
      testResult
    }
    integrityStatus {
      testResult
      timestamp
    }
  }
}
    `;
export const useListClustersQuery = <
  TData = GQLListClustersQuery,
  TError = unknown
>(
  variables?: GQLListClustersQueryVariables,
  options?: UseQueryOptions<GQLListClustersQuery, TError, TData>
) =>
  useQuery<GQLListClustersQuery, TError, TData>(
    variables === undefined ? ['listClusters'] : ['listClusters', variables],
    axiosHelper<GQLListClustersQuery, GQLListClustersQueryVariables>(
      ListClustersDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListClustersQuery = <
  TData = GQLListClustersQuery,
  TError = unknown
>(
  variables?: GQLListClustersQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListClustersQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLListClustersQuery,
    GQLListClustersQueryVariables
  >(ListClustersDocument);
  return useInfiniteQuery<GQLListClustersQuery, TError, TData>(
    variables === undefined
      ? ['listClusters.infinite']
      : ['listClusters.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListColumnsDocument = `
    query listColumns($systemId: String, $status: String, $skip: Int, $limit: Int) {
  listColumns(systemId: $systemId, status: $status, skip: $skip, limit: $limit) {
    id
    companyId
    companySiteId
    areaId
    systemId
    name
    cassettes
    sortOrder
    columnRecentRunningStatus {
      actualDp {
        dP
      }
      recentRunningStatus {
        percent
        status
      }
      currentColumnStatus
    }
    status
  }
}
    `;
export const useListColumnsQuery = <
  TData = GQLListColumnsQuery,
  TError = unknown
>(
  variables?: GQLListColumnsQueryVariables,
  options?: UseQueryOptions<GQLListColumnsQuery, TError, TData>
) =>
  useQuery<GQLListColumnsQuery, TError, TData>(
    variables === undefined ? ['listColumns'] : ['listColumns', variables],
    axiosHelper<GQLListColumnsQuery, GQLListColumnsQueryVariables>(
      ListColumnsDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListColumnsQuery = <
  TData = GQLListColumnsQuery,
  TError = unknown
>(
  variables?: GQLListColumnsQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListColumnsQuery, TError, TData>
) => {
  const query = axiosHelper<GQLListColumnsQuery, GQLListColumnsQueryVariables>(
    ListColumnsDocument
  );
  return useInfiniteQuery<GQLListColumnsQuery, TError, TData>(
    variables === undefined
      ? ['listColumns.infinite']
      : ['listColumns.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListCompaniesDocument = `
    query listCompanies($name: String, $status: String, $skip: Int, $limit: Int) {
  listCompanies(name: $name, status: $status, skip: $skip, limit: $limit) {
    id
    name
    companySiteCount {
      count
    }
    companySystemsCount {
      profi
      cbs
      cfs
      keraflux
      count
    }
    address {
      address1
      address2
      city
      state
      zipcode
      country
    }
    status
    liveStatus {
      systems {
        type
        count
      }
    }
  }
}
    `;
export const useListCompaniesQuery = <
  TData = GQLListCompaniesQuery,
  TError = unknown
>(
  variables?: GQLListCompaniesQueryVariables,
  options?: UseQueryOptions<GQLListCompaniesQuery, TError, TData>
) =>
  useQuery<GQLListCompaniesQuery, TError, TData>(
    variables === undefined ? ['listCompanies'] : ['listCompanies', variables],
    axiosHelper<GQLListCompaniesQuery, GQLListCompaniesQueryVariables>(
      ListCompaniesDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListCompaniesQuery = <
  TData = GQLListCompaniesQuery,
  TError = unknown
>(
  variables?: GQLListCompaniesQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListCompaniesQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLListCompaniesQuery,
    GQLListCompaniesQueryVariables
  >(ListCompaniesDocument);
  return useInfiniteQuery<GQLListCompaniesQuery, TError, TData>(
    variables === undefined
      ? ['listCompanies.infinite']
      : ['listCompanies.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListCompanySitesDocument = `
    query listCompanySites($companyId: String, $group: String, $name: String, $status: String, $skip: Int, $limit: Int) {
  listCompanySites(
    companyId: $companyId
    group: $group
    name: $name
    status: $status
    skip: $skip
    limit: $limit
  ) {
    address {
      address1
      address2
      city
      state
      zipcode
      country
    }
    id
    name
    longitude
    latitude
    status
    groups
    companyId
    liveKPI {
      efficiencyRank
      siteEfficiencyPct
      productionCumIdleStandbyTimeMinutes
      productionCumCleanTimeMinutes
      productionCumProductionTimeMinutes
    }
    liveStatus {
      activeAlertCount
      systems {
        type
        count
      }
    }
    siteSystemsCount {
      profi
      cbs
      cfs
      keraflux
      count
    }
  }
}
    `;

export const useListCompanySitesQuery = <
  TData = GQLListCompanySitesQuery,
  TError = unknown
>(
  variables?: GQLListCompanySitesQueryVariables,
  options?: UseQueryOptions<GQLListCompanySitesQuery, TError, TData>
) =>
  useQuery<GQLListCompanySitesQuery, TError, TData>(
    variables === undefined
      ? ['listCompanySites']
      : ['listCompanySites', variables],
    axiosHelper<GQLListCompanySitesQuery, GQLListCompanySitesQueryVariables>(
      ListCompanySitesDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListCompanySitesQuery = <
  TData = GQLListCompanySitesQuery,
  TError = unknown
>(
  variables?: GQLListCompanySitesQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListCompanySitesQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLListCompanySitesQuery,
    GQLListCompanySitesQueryVariables
  >(ListCompanySitesDocument);
  return useInfiniteQuery<GQLListCompanySitesQuery, TError, TData>(
    variables === undefined
      ? ['listCompanySites.infinite']
      : ['listCompanySites.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListConsumablesDocument = `
    query listConsumables($systemId: String, $blockId: String, $status: String, $skip: Int, $limit: Int) {
  listConsumables(
    systemId: $systemId
    blockId: $blockId
    status: $status
    skip: $skip
    limit: $limit
  ) {
    id
    companyId
    companySiteId
    areaId
    systemId
    blockId
    productNumber
    status
    liveKPI {
      totalFilteredL
      totalCipCycles
    }
    liveStatus {
      moduleId
      serialNumber
      placeNumber
      clusterNumber
      moduleNumber
      blockNumber
      installationTime
      pinCount
      pins {
        key
        value
      }
    }
  }
}
    `;
export const useListConsumablesQuery = <
  TData = GQLListConsumablesQuery,
  TError = unknown
>(
  variables?: GQLListConsumablesQueryVariables,
  options?: UseQueryOptions<GQLListConsumablesQuery, TError, TData>
) =>
  useQuery<GQLListConsumablesQuery, TError, TData>(
    variables === undefined
      ? ['listConsumables']
      : ['listConsumables', variables],
    axiosHelper<GQLListConsumablesQuery, GQLListConsumablesQueryVariables>(
      ListConsumablesDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListConsumablesQuery = <
  TData = GQLListConsumablesQuery,
  TError = unknown
>(
  variables?: GQLListConsumablesQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListConsumablesQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLListConsumablesQuery,
    GQLListConsumablesQueryVariables
  >(ListConsumablesDocument);
  return useInfiniteQuery<GQLListConsumablesQuery, TError, TData>(
    variables === undefined
      ? ['listConsumables.infinite']
      : ['listConsumables.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListFilterRunsDocument = `
    query listFilterRuns($systemId: ID!, $startTime: String!, $endTime: String!) {
  listFilterRuns(id: $systemId, startTime: $startTime, endTime: $endTime) {
    filterRunId
    systemId
    startTime
    endTime
    volumeFiltered
    brandName
    totalRecoveredBeer
  }
}
    `;
export const useListFilterRunsQuery = <
  TData = GQLListFilterRunsQuery,
  TError = unknown
>(
  variables: GQLListFilterRunsQueryVariables,
  options?: UseQueryOptions<GQLListFilterRunsQuery, TError, TData>
) =>
  useQuery<GQLListFilterRunsQuery, TError, TData>(
    ['listFilterRuns', variables],
    axiosHelper<GQLListFilterRunsQuery, GQLListFilterRunsQueryVariables>(
      ListFilterRunsDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListFilterRunsQuery = <
  TData = GQLListFilterRunsQuery,
  TError = unknown
>(
  variables: GQLListFilterRunsQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListFilterRunsQuery, TError, TData>
) => {
  const query = axiosHelper<
    GQLListFilterRunsQuery,
    GQLListFilterRunsQueryVariables
  >(ListFilterRunsDocument);
  return useInfiniteQuery<GQLListFilterRunsQuery, TError, TData>(
    ['listFilterRuns.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListReportDefinitionsDocument = `
    query listReportDefinitions($companyId: String, $companySiteId: String, $systemId: String, $skip: Int, $limit: Int) {
  listReportDefinitions(
    companyId: $companyId
    companySiteId: $companySiteId
    systemId: $systemId
    skip: $skip
    limit: $limit
  ) {
    id
    reportName
    reportType
    frequency
    companyId
    aggregation
    systems {
      companySiteId
      systemId
    }
  }
}
    `;
export const useListReportDefinitionsQuery = <
  TData = GQLListReportDefinitionsQuery,
  TError = unknown
>(
  variables?: GQLListReportDefinitionsQueryVariables,
  options?: UseQueryOptions<GQLListReportDefinitionsQuery, TError, TData>
) =>
  useQuery<GQLListReportDefinitionsQuery, TError, TData>(
    variables === undefined
      ? ['listReportDefinitions']
      : ['listReportDefinitions', variables],
    axiosHelper<
      GQLListReportDefinitionsQuery,
      GQLListReportDefinitionsQueryVariables
    >(ListReportDefinitionsDocument).bind(null, variables),
    options
  );
export const useInfiniteListReportDefinitionsQuery = <
  TData = GQLListReportDefinitionsQuery,
  TError = unknown
>(
  variables?: GQLListReportDefinitionsQueryVariables,
  options?: UseInfiniteQueryOptions<
    GQLListReportDefinitionsQuery,
    TError,
    TData
  >
) => {
  const query = axiosHelper<
    GQLListReportDefinitionsQuery,
    GQLListReportDefinitionsQueryVariables
  >(ListReportDefinitionsDocument);
  return useInfiniteQuery<GQLListReportDefinitionsQuery, TError, TData>(
    variables === undefined
      ? ['listReportDefinitions.infinite']
      : ['listReportDefinitions.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListReportsDocument = `
    query listReports($companyId: String, $companySiteId: String, $systemId: String, $skip: Int, $limit: Int) {
  listReports(
    companyId: $companyId
    companySiteId: $companySiteId
    systemId: $systemId
    skip: $skip
    limit: $limit
  ) {
    id
    name
    type
    frequency
    startDate
    endDate
    aggregation
    companyId
    createdOn
    createdBy
    systems {
      companySiteId
      systemId
    }
    generatedOn
    reportS3Key
    status
    statusLastUpdate
  }
}
    `;
export const useListReportsQuery = <
  TData = GQLListReportsQuery,
  TError = unknown
>(
  variables?: GQLListReportsQueryVariables,
  options?: UseQueryOptions<GQLListReportsQuery, TError, TData>
) =>
  useQuery<GQLListReportsQuery, TError, TData>(
    variables === undefined ? ['listReports'] : ['listReports', variables],
    axiosHelper<GQLListReportsQuery, GQLListReportsQueryVariables>(
      ListReportsDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListReportsQuery = <
  TData = GQLListReportsQuery,
  TError = unknown
>(
  variables?: GQLListReportsQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListReportsQuery, TError, TData>
) => {
  const query = axiosHelper<GQLListReportsQuery, GQLListReportsQueryVariables>(
    ListReportsDocument
  );
  return useInfiniteQuery<GQLListReportsQuery, TError, TData>(
    variables === undefined
      ? ['listReports.infinite']
      : ['listReports.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListSystemsDocument = `
    query listSystems($companyId: String, $companySiteId: String, $status: String, $skip: Int, $limit: Int) {
  listSystems(
    companyId: $companyId
    companySiteId: $companySiteId
    status: $status
    skip: $skip
    limit: $limit
  ) {
    id
    companyId
    companySiteId
    areaId
    name
    deploymentDate
    serialNumber
    modelNumber
    status
    type
    liveKPI {
      efficiencyPct
      throughputHlCassette
      hlStablized
      tmpBar
      cipCyclesSinceLastRefill
      productionCipCycles
      productionCumThroughputHlM2
      productionCumVolumeHl
      productionCumFlowHlh
      productionNetCapacityLmh
      actualDp
      actualFlow
      lineTurbidityEbc
      lineOxygenPpb
      lastHourAverageFlow
    }
    liveStatus {
      recentSystemStatus {
        status
        percent
      }
      currentSystemStatus
      productionBrand
      installedClusters
      operationalClusters
      productionStartTime
      productionTargetVolumeHl
      productionRemainingVolumeHl
      processedVolumeHl
      productionPercentCompletePct
      remainingTimeSeconds
      lastAlarm
      serviceOverdue
      diaFiltration
    }
    clusterStatus {
      id
      clusterLabel
      blockLabel
      currentStatus
      testResult
      testTime {
        ScalarValue
      }
    }
    cleanUpLiveKPI {
      waterConsumptionM3Hl
      causticLow
      acidLow
      peroxideLow
      membraneCleanerLow
      timestamp
    }
    cleanUpLiveStatus {
      recentCleanUpUnitStatus {
        status
        percent
      }
      currentCleanUpUnitStatus
    }
  liveRegeneration {
        phMeter
        recentRunningStatus {
            status
            percent
        }
        cleanTypeSanitation {
            lastClean
            processedVolumeHl
        }
        cleanTypeCaustic {
            lastClean
            processedVolumeHl
        }
        cleanTypeEnzymatic {
            lastClean
            processedVolumeHl
        }
    }
systemSettingsInfo {
  consumption {
      acidicChemicalL
      airKg
      beerLostL
      causticKg
      cipEnergykWh
      co2Kg
      daWater
      dawCount
      enzymaticChemicalL
      membraneCleanerKg
      nitricAcidicChemicalL
      peroxideL
      platoDegree
      stablizationConsthL
      waterL
  }
  every10thCycle {
      acidicChemicalL
  }
  thresholdValues {
      targetFlowRateHlh
  }
  degreeOfEfficiency {
      green
      red
      yellow
  }
  oxygen {
      green
      red
      yellow
  }
  netCapacity {
      green
      red
      yellow
  }
  waterHl {
      green
      red
      yellow
  }
  daWaterHl {
      green
      red
      yellow
  }
  opex {
      green
      red
      yellow
  }
  peroxyde {
      green
      red
      yellow
  }
  membraneCleaner {
      green
      red
      yellow
  }
  caustic {
      green
      red
      yellow
  }
  concentrateStatus {
      green
      red
      yellow
  }
  
  actualFlow {
      green
      red
      yellow
  }
  actualDp {
      green
      red
      yellow
  }
  cluster {
      green
      red
      yellow
  }
  turbidityEbc {
      green
      red
      yellow
  }
  operationalCluster {
      green
      red
      yellow
  }
  serviceSettings {
      profi { 
          value 
          unitOfMeasure
      }
      cbs { 
          value 
          unitOfMeasure
      }
      cfs { 
          value 
          unitOfMeasure
      }
      keraflux { 
          value 
          unitOfMeasure
      }
  }
}
    sortOrder
  }
}
    `;

export const useListSystemsQuery = <
  TData = GQLListSystemsQuery,
  TError = unknown
>(
  variables?: GQLListSystemsQueryVariables,
  options?: UseQueryOptions<GQLListSystemsQuery, TError, TData>
) =>
  useQuery<GQLListSystemsQuery, TError, TData>(
    variables === undefined ? ['listSystems'] : ['listSystems', variables],
    axiosHelper<GQLListSystemsQuery, GQLListSystemsQueryVariables>(
      ListSystemsDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListSystemsQuery = <
  TData = GQLListSystemsQuery,
  TError = unknown
>(
  variables?: GQLListSystemsQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListSystemsQuery, TError, TData>
) => {
  const query = axiosHelper<GQLListSystemsQuery, GQLListSystemsQueryVariables>(
    ListSystemsDocument
  );
  return useInfiniteQuery<GQLListSystemsQuery, TError, TData>(
    variables === undefined
      ? ['listSystems.infinite']
      : ['listSystems.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};

export const ListUsersDocument = `
    query listUsers($companyId: String, $skip: Int, $limit: Int, $status: String) {
  listUsers(companyId: $companyId, skip: $skip, limit: $limit, status: $status) {
    userId
    companyId
    email
    firstName
    lastName
    phoneNumber
    status
    sub
    liveStatus {
      lastActivityRouteKey
      lastActivityTimestamp
    }
  }
}
    `;
export const useListUsersQuery = <TData = GQLListUsersQuery, TError = unknown>(
  variables?: GQLListUsersQueryVariables,
  options?: UseQueryOptions<GQLListUsersQuery, TError, TData>
) =>
  useQuery<GQLListUsersQuery, TError, TData>(
    variables === undefined ? ['listUsers'] : ['listUsers', variables],
    axiosHelper<GQLListUsersQuery, GQLListUsersQueryVariables>(
      ListUsersDocument
    ).bind(null, variables),
    options
  );
export const useInfiniteListUsersQuery = <
  TData = GQLListUsersQuery,
  TError = unknown
>(
  variables?: GQLListUsersQueryVariables,
  options?: UseInfiniteQueryOptions<GQLListUsersQuery, TError, TData>
) => {
  const query = axiosHelper<GQLListUsersQuery, GQLListUsersQueryVariables>(
    ListUsersDocument
  );
  return useInfiniteQuery<GQLListUsersQuery, TError, TData>(
    variables === undefined
      ? ['listUsers.infinite']
      : ['listUsers.infinite', variables],
    (metaData) => query({ ...variables, ...(metaData.pageParam ?? {}) }),
    options
  );
};
