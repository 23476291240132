
export const PrefetchListSystemsDocument = `
query listSystems($companyId: String, $companySiteId: String, $status: String, $skip: Int, $limit: Int) {
listSystems(
companyId: $companyId
companySiteId: $companySiteId
status: $status
skip: $skip
limit: $limit
) {
id
companyId
companySiteId
areaId
name
deploymentDate
serialNumber
modelNumber
type
sortOrder

systemSettingsInfo {
    consumption {
        acidicChemicalL
        airKg
        beerLostL
        causticKg
        cipEnergykWh
        co2Kg
        daWater
        dawCount
        enzymaticChemicalL
        membraneCleanerKg
        nitricAcidicChemicalL
        peroxideL
        platoDegree
        stablizationConsthL
        waterL
    }
    every10thCycle {
        acidicChemicalL
    }
    thresholdValues {
        targetFlowRateHlh
    }
    degreeOfEfficiency {
        green
        red
        yellow
    }
    oxygen {
        green
        red
        yellow
    }
    netCapacity {
        green
        red
        yellow
    }
    waterHl {
        green
        red
        yellow
    }
    daWaterHl {
        green
        red
        yellow
    }
    opex {
        green
        red
        yellow
    }
    peroxyde {
        green
        red
        yellow
    }
    membraneCleaner {
        green
        red
        yellow
    }
    caustic {
        green
        red
        yellow
    }
    concentrateStatus {
        green
        red
        yellow
    }
    
    actualFlow {
        green
        red
        yellow
    }
    actualDp {
        green
        red
        yellow
    }
    cluster {
        green
        red
        yellow
    }
    turbidityEbc {
        green
        red
        yellow
    }
    operationalCluster {
        green
        red
        yellow
    }
    serviceSettings {
        profi { 
            value 
            unitOfMeasure
        }
        cbs { 
            value 
            unitOfMeasure
        }
        cfs { 
            value 
            unitOfMeasure
        }
        keraflux { 
            value 
            unitOfMeasure
        }
    }
}
}
}
`;
